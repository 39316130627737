import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { List, Expander, Row, Table, Attributes, InfoIcon, Tabs } from './components';
import { browseAppsCurl, browseAppsJava, browseAppsJavascript, browseAppsNode, browseAppsPerl, browseAppsPython, browseAppsPhp, browseAppsRuby, browseAppsGo, browseAppsC, browseAppsVB, browseAppsGroovy, browseAppsObjectiveC, browseAppsSwift } from 'requestExamples/apps';
export const endpoints = [{
  param: 'GET',
  value: '/nodes/app/{path}?children={numberOfChildren}&acl={acl}&eprop={eprop}&search={search}&page={page}&per_page={perPage}&sort={sort}&direction={direction}',
  link: '#browse-apps'
}];
export const baseUrlBrowse = [{
  param: 'GET',
  value: '/nodes/app/{path}?children={numberOfChildren}&acl={acl}&eprop={eprop}&search={search}&page={page}&per_page={perPage}&sort={sort}&direction={direction}'
}];
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  endpoints,
  baseUrlBrowse,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "apps"
    }}>{`Apps`}</h1>
    <Row equalWidth mdxType="Row">
	<div>
		<p>
			When using the application integration model, you need to use the /nodes/app structure to access your
			pre-defined folder structure.
			<br />
			<br />
			For more information about what apps are available and how to integrate SmartVault on each one <a href="https://support.smartvault.com/Topics/Integrations" target="_blank">
				click here
			</a>
		</p>
	</div>
	<Table title="endpoints" rows={endpoints} mdxType="Table" />
    </Row>
    <br />
    <h2 {...{
      "id": "the-app-object"
    }}>{`The App object`}</h2>
    <Expander title="Attributes" mdxType="Expander">
	<Attributes items={[{
        name: 'name',
        type: 'string',
        description: 'The name of the app.'
      }, {
        name: 'createdOn',
        type: 'string',
        description: 'Date when the app was created.'
      }, {
        name: 'modifiedOn',
        type: 'string',
        description: 'Last time the app was modified.'
      }, {
        name: 'uri',
        type: 'string',
        description: 'Uri of the app.'
      }, {
        name: 'your_permissions',
        type: 'number',
        description: 'The permissions the current authenticated user has to this app.'
      }]} mdxType="Attributes" />
    </Expander>
    <br />
    <br />
    <br />
    <br />
    <hr />
    <br />
    <br />
    <h2 {...{
      "id": "browse-apps"
    }}>{`Browse apps`}</h2>

    <Row equalWidth mdxType="Row">
	<div>
		<p>
			Browse pre-defined application folder structures in SmartVault. There are some query parameters explained
			below that makes the retrieval more precise.
		</p>
	</div>
	<Table title="Browse apps" rows={baseUrlBrowse} mdxType="Table" />
    </Row>
    <br />
    <p><strong parentName="p">{`Query parameters`}</strong></p>
    <hr></hr>
    <Expander title="query parameters" mdxType="Expander">
	<Attributes items={[{
        name: 'path',
        type: 'string (optional)',
        description: 'The app path being browsed, including the account and vault'
      }, {
        name: 'children',
        type: 'number (optional)',
        description: 'Specifies the depth of children to fetch back. 0 is just the element specified, and 1 is just the direct children. Can be any integer value "0" or greater. Default is 0.'
      }, {
        name: 'acl',
        type: 'boolean (optional)',
        description: 'Specifies whether or not to include the access control (permissions) information in the response. This information is expensive and should only be requested if needed. The value may be set to "0", "1", "true" or "false". Default is false.'
      }, {
        name: 'eprop',
        type: 'boolean (optional)',
        description: 'Tells the server to return extended property information in the results. It may be set to "0", "1", "true" or "false". Default is false.'
      }, {
        name: 'search',
        type: 'string (optional)',
        description: 'Sets the query text or search phrase the user wishes to search for. When setting this parameter, the query will only return files matching the search phrase. This parameter may be used on nodes where the nodeType field is "AccountNodeType", "VaultNodeType" or "FolderNodeType".'
      }, {
        name: 'page',
        type: 'number (optional)',
        description: 'Sets the zero-based number of the result page to be returned. When combined with per_page and a sorting criteria, this can be used to paginate through larger result sets. Default is 0.'
      }, {
        name: 'per_page',
        type: 'number (optional)',
        description: 'Sets the maximum number of items to return in the query. Default is 50.'
      }, {
        name: 'sort',
        type: 'string (optional)',
        description: 'Sets the information for the results to be ordered. The value "relevance" is used for best-match sorting of search results. Default is "name".',
        values: ['name', 'modified_date', 'size', 'relevance']
      }, {
        name: 'direction',
        type: 'string (optional)',
        description: 'Sets the sorting direction. Can be either "asc" for ascending results or "desc" for descending. Default is "asc".',
        values: ['asc', 'desc']
      }, {
        name: 'record_id',
        type: 'string (optional)',
        description: 'When browsing an application object folder, this limits the items returned. For example, if browsing QuickBooks customers in "/nodes/app/QuickBooks/%ApplicationId%/Customers", the record_id would be the customer identifier.'
      }]} mdxType="Attributes" />
    </Expander>
    <br />
    <br />
    <p><strong parentName="p">{`Request`}</strong></p>
    <hr></hr>
    <Tabs items={[{
      tabTitle: 'cURL',
      tabInfo: browseAppsCurl
    }, {
      tabTitle: 'Java',
      tabInfo: browseAppsJava
    }, {
      tabTitle: 'Javascript',
      tabInfo: browseAppsJavascript
    }, {
      tabTitle: 'Node',
      tabInfo: browseAppsNode
    }, {
      tabTitle: 'Perl',
      tabInfo: browseAppsPerl
    }, {
      tabTitle: 'Python',
      tabInfo: browseAppsPython
    }, {
      tabTitle: 'PHP',
      tabInfo: browseAppsPhp
    }, {
      tabTitle: 'Ruby',
      tabInfo: browseAppsRuby
    }, {
      tabTitle: 'Go',
      tabInfo: browseAppsGo
    }, {
      tabTitle: 'C#',
      tabInfo: browseAppsC
    }, {
      tabTitle: 'Visual Basic',
      tabInfo: browseAppsVB
    }, {
      tabTitle: 'Groovy',
      tabInfo: browseAppsGroovy
    }, {
      tabTitle: 'Objective-C',
      tabInfo: browseAppsObjectiveC
    }, {
      tabTitle: 'Swift',
      tabInfo: browseAppsSwift
    }]} mdxType="Tabs" />
    <br />
    <p><strong parentName="p">{`Response`}</strong></p>
    <hr></hr>
    <p>{`Returns all the app types as the default structure of `}<a parentName="p" {...{
        "href": "#the-app-object"
      }}>{`app objects`}</a>{`.`}</p>
    <Expander title="success object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
  "error": {
    "success": true
  },
  "message": {
    "name": "Applications",
    "createdOn": "2014-10-27T12:41:43-05:00",
    "modifiedOn": "2014-10-27T12:41:43-05:00",
    "uri": "/nodes/app",
    "your_permissions": 64,
    "total_children": 19,
    "children": [
      {
        "name": "Xero.com_beta-01",
        "createdOn": "2014-10-27T12:41:43-05:00",
        "modifiedOn": "2014-10-27T12:41:43-05:00",
        "uri": "/nodes/app/Xero.com_beta-01",
        "your_permissions": 64
      },
      {
        "name": "Results A",
        "createdOn": "2014-10-27T12:41:43-05:00",
        "modifiedOn": "2014-10-27T12:41:43-05:00",
        "uri": "/nodes/app/Results A",
        "your_permissions": 64
      },
      {
        "name": "ACCTivate_v1",
        "createdOn": "2014-10-27T12:41:43-05:00",
        "modifiedOn": "2014-10-27T12:41:43-05:00",
        "uri": "/nodes/app/ACCTivate_v1",
        "your_permissions": 64
      },
      {
        "name": "IonTest",
        "createdOn": "2014-10-27T12:41:43-05:00",
        "modifiedOn": "2014-10-27T12:41:43-05:00",
        "uri": "/nodes/app/IonTestType",
        "your_permissions": 64
      },
      {
        "name": "SampleCRM",
        "createdOn": "2014-10-27T12:41:43-05:00",
        "modifiedOn": "2014-10-27T12:41:43-05:00",
        "uri": "/nodes/app/SampleCRM",
        "your_permissions": 64
      },
      {
        "name": "QuickBooks",
        "createdOn": "2014-10-27T12:41:43-05:00",
        "modifiedOn": "2014-10-27T12:41:43-05:00",
        "uri": "/nodes/app/QuickBooks",
        "your_permissions": 64
      },
      {
        "name": "ACCTivate_v1",
        "createdOn": "2014-10-27T12:41:43-05:00",
        "modifiedOn": "2014-10-27T12:41:43-05:00",
        "uri": "/nodes/app/ACCTivate!",
        "your_permissions": 64
      },
      {
        "name": "QuickBooks Online",
        "createdOn": "2014-10-27T12:41:43-05:00",
        "modifiedOn": "2014-10-27T12:41:43-05:00",
        "uri": "/nodes/app/QuickBooksOnline",
        "your_permissions": 64
      },
      {
        "name": "Test application",
        "createdOn": "2014-10-27T12:41:43-05:00",
        "modifiedOn": "2014-10-27T12:41:43-05:00",
        "uri": "/nodes/app/nexonia",
        "your_permissions": 64
      },
      {
        "name": "Testing",
        "createdOn": "2014-10-27T12:41:43-05:00",
        "modifiedOn": "2014-10-27T12:41:43-05:00",
        "uri": "/nodes/app/Test",
        "your_permissions": 64
      },
      {
        "name": "FreshBooks",
        "createdOn": "2014-10-27T12:41:43-05:00",
        "modifiedOn": "2014-10-27T12:41:43-05:00",
        "uri": "/nodes/app/FreshBooks",
        "your_permissions": 64
      },
      {
        "name": "Salesforce",
        "createdOn": "2014-10-27T12:41:43-05:00",
        "modifiedOn": "2014-10-27T12:41:43-05:00",
        "uri": "/nodes/app/SalesforceTest",
        "your_permissions": 64
      },
      {
        "name": "XpandedReportsClient",
        "createdOn": "2014-10-27T12:41:43-05:00",
        "modifiedOn": "2014-10-27T12:41:43-05:00",
        "uri": "/nodes/app/Client",
        "your_permissions": 64
      },
      {
        "name": "KEN1234",
        "createdOn": "2014-10-27T12:41:43-05:00",
        "modifiedOn": "2014-10-27T12:41:43-05:00",
        "uri": "/nodes/app/LacerteSample",
        "your_permissions": 64
      },
      {
        "name": "Intuit Practice Management",
        "createdOn": "2014-10-27T12:41:43-05:00",
        "modifiedOn": "2014-10-27T12:41:43-05:00",
        "uri": "/nodes/app/IntuitPracticeManagement",
        "your_permissions": 64
      },
      {
        "name": "ACCTivate!",
        "createdOn": "2014-10-27T12:41:43-05:00",
        "modifiedOn": "2014-10-27T12:41:43-05:00",
        "uri": "/nodes/app/ACCTivate_v2",
        "your_permissions": 64
      },
      {
        "name": "Testasdf",
        "createdOn": "2014-10-27T12:41:43-05:00",
        "modifiedOn": "2014-10-27T12:41:43-05:00",
        "uri": "/nodes/app/TestClientasdf",
        "your_permissions": 64
      },
      {
        "name": "Xero.com",
        "createdOn": "2014-10-27T12:41:43-05:00",
        "modifiedOn": "2014-10-27T12:41:43-05:00",
        "uri": "/nodes/app/Xero.com",
        "your_permissions": 64
      },
      {
        "name": "DOCS UPLOAD",
        "createdOn": "2014-10-27T12:41:43-05:00",
        "modifiedOn": "2014-10-27T12:41:43-05:00",
        "uri": "/nodes/app/DocsUpload",
        "your_permissions": 64
      }
    ]
  }
}
`}</code></pre>
    </Expander>
    <br />
    <br />
    <p>{`Returns an `}<a parentName="p" {...{
        "href": "/api/errors#error-object"
      }}>{`error object`}</a>{` if the path doesn't exist.`}</p>
    <Expander title="error object" mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": false,
        "error_code": -520093684,
        "error_text": "Object not found.",
        "instance_guid": "693476f2-8971-450d-a7b6-287cc986f3df",
        "status_code": 404
    }
}
`}</code></pre>
    </Expander>
    <br />
    <br />
    <h4 {...{
      "id": "example-of-an-output-of-the-app-structure-when-a-custom-app-has-been-added"
    }}>{`Example of an output of the app structure when a custom app has been added.`}</h4>
    <hr></hr>
    <p>{`After adding an app, it will show under the default app type path. You'll need to query using the `}<inlineCode parentName="p">{`children`}</inlineCode>{` param like `}<inlineCode parentName="p">{`/nodes/app?children=4`}</inlineCode><br /><br />{`
In the example, if you check under the "QuickBooks Online" node, you'll find the new application that we previously added called "QuickBooks". It has its own default structure and a image called "SCREENS.png" that we added just for testing purposes.`}<br /></p>
    <br />
    <Expander mdxType="Expander">
      <pre><code parentName="pre" {...{
          "className": "language-javascript"
        }}>{`{
    "error": {
        "success": true
    },
    "message": {
        "name": "Applications",
        "createdOn": "2020-06-10T11:39:07-05:00",
        "modifiedOn": "2020-06-10T11:39:07-05:00",
        "uri": "/nodes/app",
        "your_permissions": 64,
        "total_children": 23,
        "children": [
            {
                "name": "Xero.com_beta-01",
                "createdOn": "2020-06-10T11:39:07-05:00",
                "modifiedOn": "2020-06-10T11:39:07-05:00",
                "uri": "/nodes/app/Xero.com_beta-01",
                "your_permissions": 64,
                "total_children": 0
            },

            ...

            {
                "name": "QuickBooks",
                "createdOn": "2020-06-10T11:39:07-05:00",
                "modifiedOn": "2020-06-10T11:39:07-05:00",
                "uri": "/nodes/app/QuickBooks",
                "your_permissions": 64,
                "total_children": 0
            },
            {
                "name": "ACCTivate_v1",
                "createdOn": "2020-06-10T11:39:07-05:00",
                "modifiedOn": "2020-06-10T11:39:07-05:00",
                "uri": "/nodes/app/ACCTivate!",
                "your_permissions": 64,
                "total_children": 0
            },
            {
                "name": "QuickBooks Online",
                "createdOn": "2020-06-10T11:39:07-05:00",
                "modifiedOn": "2020-06-10T11:39:07-05:00",
                "uri": "/nodes/app/QuickBooksOnline",
                "your_permissions": 64,
                "total_children": 1,
                "children": [
                    {
                        "name": "QuickBooks",
                        "createdOn": "2020-06-10T11:39:07-05:00",
                        "modifiedOn": "2020-06-10T11:39:07-05:00",
                        "uri": "/nodes/app/QuickBooksOnline/9130348643023896",
                        "dav_uri": "/nodes/pth/SmartVault Account/Firm/Public Documents/Applications/QuickBooks Online/QuickBooks",
                        "your_permissions": 10481,
                        "total_children": 21,
                        "children": [
                            {
                                "name": "SVault-Inbox",
                                "createdOn": "2020-06-10T11:39:07-05:00",
                                "modifiedOn": "2020-06-10T11:39:07-05:00",
                                "uri": "/nodes/app/QuickBooksOnline/9130348643023896/SVault-Inbox",
                                "dav_uri": "/nodes/pth/SmartVault Account/Firm/Public Documents/Applications/QuickBooks Online/QuickBooks/Documents/Inbox",
                                "your_permissions": 64,
                                "total_children": 1,
                                "children": [
                                    {
                                        "name": "SCREENS.png",
                                        "description": "",
                                        "createdOn": "2020-06-10T10:38:33-06:00",
                                        "modifiedOn": "2020-06-10T10:38:34-06:00",
                                        "size": 963944,
                                        "uri": "/nodes/app/SmartVault Account/Firm/Public Documents/Applications/QuickBooks Online/QuickBooks/Documents/Inbox/SCREENS.png",
                                        "dav_uri": "/nodes/pth/SmartVault Account/Firm/Public Documents/Applications/QuickBooks Online/QuickBooks/Documents/Inbox/SCREENS.png",
                                        "download_uri": "/files/id/Document/gobaow7BM0y634DNam18FA",
                                        "your_permissions": 41943153,
                                        "nodeType": "FileNodeType",
                                        "link_uri": "https://my.smartvault.com/users/secure/ElementBrowser.aspx?type=Document&id=gobaow7BM0y634DNam18FA",
                                        "preview_link_uri": "https://my.smartvault.com/users/secure/ElementBrowser.aspx?type=Document&id=gobaow7BM0y634DNam18FA",
                                        "download_link_uri": "https://my.smartvault.com/users/secure/DownloadFile.aspx?d=gobaow7BM0y634DNam18FA",
                                        "auto_name": false,
                                        "auto_description": false
                                    }
                                ]
                            },
                            {
                                "name": "Company-Snapshot",
                                "createdOn": "2020-06-10T11:39:07-05:00",
                                "modifiedOn": "2020-06-10T11:39:07-05:00",
                                "uri": "/nodes/app/QuickBooksOnline/9130348643023896/Company-Snapshot",
                                "dav_uri": "/nodes/pth/SmartVault Account/Firm/Public Documents/Applications/QuickBooks Online/QuickBooks/Documents/Snapshots",
                                "your_permissions": 64,
                                "total_children": 0
                            },
                            {
                                "name": "Customer",
                                "createdOn": "2020-06-10T11:39:07-05:00",
                                "modifiedOn": "2020-06-10T11:39:07-05:00",
                                "uri": "/nodes/app/QuickBooksOnline/9130348643023896/Customer",
                                "dav_uri": "/nodes/pth/SmartVault Account/Firm/Public Documents/Applications/QuickBooks Online/QuickBooks/Documents/Customers",
                                "your_permissions": 64,
                                "total_children": 0
                            },
                            {
                                "name": "Vendor",
                                "createdOn": "2020-06-10T11:39:07-05:00",
                                "modifiedOn": "2020-06-10T11:39:07-05:00",
                                "uri": "/nodes/app/QuickBooksOnline/9130348643023896/Vendor",
                                "dav_uri": "/nodes/pth/SmartVault Account/Firm/Public Documents/Applications/QuickBooks Online/QuickBooks/Documents/Vendors",
                                "your_permissions": 64,
                                "total_children": 0
                            },
                            {
                                "name": "Employee",
                                "createdOn": "2020-06-10T11:39:07-05:00",
                                "modifiedOn": "2020-06-10T11:39:07-05:00",
                                "uri": "/nodes/app/QuickBooksOnline/9130348643023896/Employee",
                                "dav_uri": "/nodes/pth/SmartVault Account/Firm/Public Documents/Applications/QuickBooks Online/QuickBooks/Documents/Employees",
                                "your_permissions": 64,
                                "total_children": 0
                            },
                            {
                                "name": "Invoice",
                                "createdOn": "2020-06-10T11:39:07-05:00",
                                "modifiedOn": "2020-06-10T11:39:07-05:00",
                                "uri": "/nodes/app/QuickBooksOnline/9130348643023896/Invoice",
                                "dav_uri": "/nodes/pth/SmartVault Account/Firm/Public Documents/Applications/QuickBooks Online/QuickBooks/Documents/Invoices",
                                "your_permissions": 64,
                                "total_children": 0
                            },
                            {
                                "name": "Payment",
                                "createdOn": "2020-06-10T11:39:07-05:00",
                                "modifiedOn": "2020-06-10T11:39:07-05:00",
                                "uri": "/nodes/app/QuickBooksOnline/9130348643023896/Payment",
                                "dav_uri": "/nodes/pth/SmartVault Account/Firm/Public Documents/Applications/QuickBooks Online/QuickBooks/Documents/Payments",
                                "your_permissions": 64,
                                "total_children": 0
                            },
                            {
                                "name": "Journal-Entry",
                                "createdOn": "2020-06-10T11:39:07-05:00",
                                "modifiedOn": "2020-06-10T11:39:07-05:00",
                                "uri": "/nodes/app/QuickBooksOnline/9130348643023896/Journal-Entry",
                                "dav_uri": "/nodes/pth/SmartVault Account/Firm/Public Documents/Applications/QuickBooks Online/QuickBooks/Documents/Journal Entries",
                                "your_permissions": 64,
                                "total_children": 0
                            },
                            {
                                "name": "Bill",
                                "createdOn": "2020-06-10T11:39:07-05:00",
                                "modifiedOn": "2020-06-10T11:39:07-05:00",
                                "uri": "/nodes/app/QuickBooksOnline/9130348643023896/Bill",
                                "dav_uri": "/nodes/pth/SmartVault Account/Firm/Public Documents/Applications/QuickBooks Online/QuickBooks/Documents/Bills and Credits",
                                "your_permissions": 64,
                                "total_children": 0
                            },
                            {
                                "name": "Charge",
                                "createdOn": "2020-06-10T11:39:07-05:00",
                                "modifiedOn": "2020-06-10T11:39:07-05:00",
                                "uri": "/nodes/app/QuickBooksOnline/9130348643023896/Charge",
                                "dav_uri": "/nodes/pth/SmartVault Account/Firm/Public Documents/Applications/QuickBooks Online/QuickBooks/Documents/Charges and Credits",
                                "your_permissions": 64,
                                "total_children": 0
                            },
                            {
                                "name": "Bill-Payment",
                                "createdOn": "2020-06-10T11:39:07-05:00",
                                "modifiedOn": "2020-06-10T11:39:07-05:00",
                                "uri": "/nodes/app/QuickBooksOnline/9130348643023896/Bill-Payment",
                                "dav_uri": "/nodes/pth/SmartVault Account/Firm/Public Documents/Applications/QuickBooks Online/QuickBooks/Documents/Bill Payments",
                                "your_permissions": 64,
                                "total_children": 0
                            },
                            {
                                "name": "Register",
                                "createdOn": "2020-06-10T11:39:07-05:00",
                                "modifiedOn": "2020-06-10T11:39:07-05:00",
                                "uri": "/nodes/app/QuickBooksOnline/9130348643023896/Register",
                                "dav_uri": "/nodes/pth/SmartVault Account/Firm/Public Documents/Applications/QuickBooks Online/QuickBooks/Documents/Registers",
                                "your_permissions": 64,
                                "total_children": 0
                            },
                            {
                                "name": "Reconcile",
                                "createdOn": "2020-06-10T11:39:07-05:00",
                                "modifiedOn": "2020-06-10T11:39:07-05:00",
                                "uri": "/nodes/app/QuickBooksOnline/9130348643023896/Reconcile",
                                "dav_uri": "/nodes/pth/SmartVault Account/Firm/Public Documents/Applications/QuickBooks Online/QuickBooks/Documents/Reconciles",
                                "your_permissions": 64,
                                "total_children": 0
                            },
                            {
                                "name": "Transfer",
                                "createdOn": "2020-06-10T11:39:07-05:00",
                                "modifiedOn": "2020-06-10T11:39:07-05:00",
                                "uri": "/nodes/app/QuickBooksOnline/9130348643023896/Transfer",
                                "dav_uri": "/nodes/pth/SmartVault Account/Firm/Public Documents/Applications/QuickBooks Online/QuickBooks/Documents/Transfers",
                                "your_permissions": 64,
                                "total_children": 0
                            },
                            {
                                "name": "Deposit",
                                "createdOn": "2020-06-10T11:39:07-05:00",
                                "modifiedOn": "2020-06-10T11:39:07-05:00",
                                "uri": "/nodes/app/QuickBooksOnline/9130348643023896/Deposit",
                                "dav_uri": "/nodes/pth/SmartVault Account/Firm/Public Documents/Applications/QuickBooks Online/QuickBooks/Documents/Deposits",
                                "your_permissions": 64,
                                "total_children": 0
                            },
                            {
                                "name": "Sales-Receipt",
                                "createdOn": "2020-06-10T11:39:07-05:00",
                                "modifiedOn": "2020-06-10T11:39:07-05:00",
                                "uri": "/nodes/app/QuickBooksOnline/9130348643023896/Sales-Receipt",
                                "dav_uri": "/nodes/pth/SmartVault Account/Firm/Public Documents/Applications/QuickBooks Online/QuickBooks/Documents/Sales Receipts",
                                "your_permissions": 64,
                                "total_children": 0
                            },
                            {
                                "name": "Credit-Memo",
                                "createdOn": "2020-06-10T11:39:07-05:00",
                                "modifiedOn": "2020-06-10T11:39:07-05:00",
                                "uri": "/nodes/app/QuickBooksOnline/9130348643023896/Credit-Memo",
                                "dav_uri": "/nodes/pth/SmartVault Account/Firm/Public Documents/Applications/QuickBooks Online/QuickBooks/Documents/Credit Memos",
                                "your_permissions": 64,
                                "total_children": 0
                            },
                            {
                                "name": "Refund",
                                "createdOn": "2020-06-10T11:39:07-05:00",
                                "modifiedOn": "2020-06-10T11:39:07-05:00",
                                "uri": "/nodes/app/QuickBooksOnline/9130348643023896/Refund",
                                "dav_uri": "/nodes/pth/SmartVault Account/Firm/Public Documents/Applications/QuickBooks Online/QuickBooks/Documents/Refunds",
                                "your_permissions": 64,
                                "total_children": 0
                            },
                            {
                                "name": "Estimate",
                                "createdOn": "2020-06-10T11:39:07-05:00",
                                "modifiedOn": "2020-06-10T11:39:07-05:00",
                                "uri": "/nodes/app/QuickBooksOnline/9130348643023896/Estimate",
                                "dav_uri": "/nodes/pth/SmartVault Account/Firm/Public Documents/Applications/QuickBooks Online/QuickBooks/Documents/Estimates",
                                "your_permissions": 64,
                                "total_children": 0
                            },
                            {
                                "name": "Purchase-Order",
                                "createdOn": "2020-06-10T11:39:07-05:00",
                                "modifiedOn": "2020-06-10T11:39:07-05:00",
                                "uri": "/nodes/app/QuickBooksOnline/9130348643023896/Purchase-Order",
                                "dav_uri": "/nodes/pth/SmartVault Account/Firm/Public Documents/Applications/QuickBooks Online/QuickBooks/Documents/Purchase Orders",
                                "your_permissions": 64,
                                "total_children": 0
                            },
                            {
                                "name": "Purchase",
                                "createdOn": "2020-06-10T11:39:07-05:00",
                                "modifiedOn": "2020-06-10T11:39:07-05:00",
                                "uri": "/nodes/app/QuickBooksOnline/9130348643023896/Purchase",
                                "dav_uri": "/nodes/pth/SmartVault Account/Firm/Public Documents/Applications/QuickBooks Online/QuickBooks/Documents/Purchases",
                                "your_permissions": 64,
                                "total_children": 0
                            }
                        ]
                    }
                ]
            },
            {
                "name": "Test application",
                "createdOn": "2020-06-10T11:39:07-05:00",
                "modifiedOn": "2020-06-10T11:39:07-05:00",
                "uri": "/nodes/app/nexonia",
                "your_permissions": 64,
                "total_children": 0
            },
            {
                "name": "Testing",
                "createdOn": "2020-06-10T11:39:07-05:00",
                "modifiedOn": "2020-06-10T11:39:07-05:00",
                "uri": "/nodes/app/Test",
                "your_permissions": 64,
                "total_children": 0
            },

            ...
        ]
    }
}
`}</code></pre>
    </Expander>
    <br /><br />
    <div>
	<InfoIcon mdxType="InfoIcon" /> You can execute a <span style={{
        "color": "#f4546d"
      }}>DELETE</span> command to the dav_uri of the children.
	Be careful while doing
    </div>
this as it will delete the app instance and its children, meaning that you'd have to go through the whole process again of
adding the app to your account.
    <br />
    <br />
    <br />
    <br />
    <br />
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      